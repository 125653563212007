/******* screen-large.less *******/
:root {
  --locationsHeight: 72px;
}
@media (max-width: 1024px) {
  :root {
    --locationsHeight: 90px;
  }
}
@media (max-width: 767px) {
  :root {
    --locationsHeight: 100px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
div.navi {
  float: left;
  display: block;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 400;
}
div.navi > .item > .menu.path {
  color: #009DE0;
}
div.sub1 > .item {
  margin: 4px 5px;
}
div.sub1 > .item.init {
  margin-left: 0;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  text-transform: uppercase;
}
div.sub2 {
  position: absolute;
  width: 205px;
  z-index: 200;
  margin-top: 0;
  background-color: #fff;
}
div.sub2 > .item {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  transition: max-height 250ms ease;
}
div.sub2 > .item > .menu {
  padding: 5px 15px;
}
div.sub2 > .item.init > .menu {
  padding-top: 15px;
}
div.sub2 > .item.exit > .menu {
  padding-bottom: 15px;
}
div.sub1 > .item:hover div.sub2 > .item {
  max-height: 150px;
  transition: max-height 700ms ease;
}
.desk {
  max-width: 62.43902439%;
  width: 840px;
}
#head {
  margin-top: 40px;
}
.wrapper {
  font-size: 18px;
  font-size: 1.8rem;
}
.head-container {
  width: calc(100% + 76px + 30px);
  margin-top: 75px;
  margin-left: -106px;
}
.backlink {
  top: 102px;
}
.service_phone {
  margin-top: 4px;
}
.service_phone:before {
  top: 0px;
  width: 20px;
  height: 23px;
  background-size: 20px 23px;
}
.mood .moodcontent {
  right: 18.7804878%;
  width: 31.2195122%;
}
@media only screen and (min-width: 1346px) {
  .mood .moodcontent {
    right: calc((100% - 840px) / 2);
  }
}
h2 {
  font-size: 24px;
  font-size: 2.4rem;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */